import React from 'react';
import Image from 'Image';
import { Container, Row, Col } from 'react-bootstrap';
import { Press } from './Press';
import FooterBottom from './FooterBottom';
import { FooterDisclosure } from './FooterDisclosure';
import { SocialMediaSchema } from './SocialMediaSchema';

export const Footer = (): JSX.Element => (
  <footer>
    <Container className="dark-background" fluid>
      <Press />
      <Row className="footer-links-container">
        <Col className="footer-col-margin" md={2} xs={12}>
          <h2>Document Categories</h2>
          <ul className="list-unstyled">
            <li className="list-unstyled">
              <a className="track-footer-link" href="/legal-forms">
                Legal Forms
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/personal">
                Personal
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/small-business">
                Business
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/tax-forms">
                Tax
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/real-estate">
                Real Estate
              </a>
            </li>
          </ul>
        </Col>
        <Col className="footer-col-margin" md={2} xs={12}>
          <h2>About FormSwift</h2>
          <ul className="list-unstyled">
            <li className="list-unstyled">
              <a className="track-footer-link" href="/">
                Home Page
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/faq.php">
                FAQ
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/contact.php">
                Contact Us
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/privacy">
                Privacy Policy
              </a>
            </li>
            <li className="list-unstyled">
              <a
                className="track-footer-link"
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  if (window.dropbox) {
                    window.dropbox.privacyConsent.openOptionsDialog();
                  }
                }}
              >
                Do Not Sell or Share my Personal Information
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="#manage-cookies">
                Cookies & CCPA preferences
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/terms">
                Terms of Service
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/copyright-policy">
                Copyright Policy
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/affiliates">
                Affiliates
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/careers">
                Careers
              </a>
            </li>
          </ul>
        </Col>
        <Col md={3} xs={12}>
          <h2>Most Popular Documents</h2>
          <ul className="list-unstyled popular-documents">
            <li className="list-unstyled">
              <a
                className="track-footer-link"
                href="/personal-financial-statement"
              >
                Personal Financial Statement
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/invoice">
                Invoice
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/release-of-liability">
                Release of Liability
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/job-application">
                Job Application
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/employment-contract">
                Employment Contract
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/non-disclosure-agreement">
                Non-disclosure Agreement
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link cc-active" href="/affidavit">
                Affidavit
              </a>
            </li>
          </ul>
        </Col>

        <Col md={2} xs={12}>
          <h2>&nbsp;</h2>
          <ul className="list-unstyled popular-documents">
            <li className="list-unstyled">
              <a className="track-footer-link" href="/bill-of-sale">
                Bill of Sale
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/employee-handbook">
                Employee Handbook
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/last-will-and-testament">
                Last Will and Testament
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/w2">
                W2
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/w9">
                W9
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/1099-misc">
                1099-Misc
              </a>
            </li>
            <li className="list-unstyled">
              <a className="track-footer-link" href="/lease-agreement">
                Lease Agreement
              </a>
            </li>
          </ul>
        </Col>

        <Col className="social-media-container-footer" md={2} xs={12}>
          <h2>Follow Us</h2>
          <a href="https://www.facebook.com/Dropbox/">
            <Image
              alt="Facebook"
              height="20"
              src="/static/images/social-media/facebook-2x.png"
              width="20"
            />
          </a>
          <a href="https://bsky.app/profile/formswift.bsky.social">
            <Image
              alt="Bluesky"
              height="20"
              src="/static/images/social-media/bluesky-2x.png"
              width="20"
            />
          </a>
          <a href="https://www.linkedin.com/company/dropbox">
            <Image
              alt="LinkedIn"
              height="20"
              src="/static/images/social-media/linkedin-2x.png"
              width="20"
            />
          </a>
          <p>
            <a className="track-footer-link" href="https://blog.formswift.com/">
              The FormSwift Blog
            </a>
          </p>
          <p>
            <a
              className="track-footer-link"
              href="https://www.inc.com/profile/formswift"
            >
              Inc. 500 Profile
            </a>
          </p>
          <h2>Job Listings</h2>
          <p>
            <a href="https://jobs.lever.co/formswift">Lever</a>
          </p>
          <p>
            <a href="https://angel.co/formswift-1/jobs">AngelList</a>
          </p>
          <p>
            <a href="https://www.glassdoor.com/Overview/Working-at-FormSwift-EI_IE1609672.11,20.htm">
              Glassdoor
            </a>
          </p>
        </Col>
      </Row>
      <Row className="footer-links-container">
        <Col xs={12}>
          <FooterDisclosure />
        </Col>
      </Row>
    </Container>
    <FooterBottom />
    <SocialMediaSchema />
  </footer>
);
