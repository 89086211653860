import React from 'react';

/**
 * Component that generates JSON-LD structured data for social media accounts
 * This helps search engines understand the organization's social presence
 * https://schema.org/Organization
 */
export const SocialMediaSchema = (): JSX.Element => {
  // Organization schema with social media links
  const organizationSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    logo: 'https://formswift.com/favicon.ico',
    name: 'FormSwift from Drobox',
    sameAs: [
      'https://www.facebook.com/Dropbox/',
      'https://bsky.app/profile/formswift.bsky.social',
      'https://www.linkedin.com/company/dropbox',
    ],
    url: 'https://formswift.com',
  };

  return (
    <script
      dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationSchema) }}
      type="application/ld+json"
    />
  );
};
