import { chatClient } from 'lib/chat/ChatClient';
import { useEffect } from 'react';
import getConfig from 'next/config';

interface RuntimeConfig {
  publicRuntimeConfig: {
    USE_DBX_CHAT: string;
    [key: string]: string | undefined;
  };
}

export const useChat = () => {
  const { publicRuntimeConfig } = getConfig() as RuntimeConfig;

  const useDBXChat = publicRuntimeConfig.USE_DBX_CHAT === 'true';

  useEffect(() => {
    if (!useDBXChat) {
      chatClient.enableChat();
    } else {
      const checkForChatClient = async () => {
        if (
          /* eslint-disable no-underscore-dangle */
          window._DBX_ZOOM_CHAT_CLIENT &&
          typeof window._DBX_ZOOM_CHAT_CLIENT.showFormSwiftChat === 'function'
        ) {
          await window._DBX_ZOOM_CHAT_CLIENT.showFormSwiftChat();
          /* eslint-enable no-underscore-dangle */
        } else {
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          setTimeout(checkForChatClient, 100);
        }
      };

      void checkForChatClient();
    }
  }, [useDBXChat]);
};
