import React, { useEffect, useMemo } from 'react';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { NextPage, NextPageContext } from 'next';
import { getCookies } from 'lib/dataFetchers';
import * as Sentry from '@sentry/nextjs';
import { useCountryData } from 'lib/useCountryData';
import isServerSide from 'Utils/isServerSide';
import getUserDevice from 'lib/optimizely/getUserDevice';
import getConfig from 'next/config';
import { CookieProps } from 'lib/optimizely/withOptimizelyProvider';
import { useGetUserSubscriptionDetailsOptimizelyQuery } from 'lib/optimizely/__generated__/optimizelyQueries.generated';

interface Config {
  publicRuntimeConfig: {
    GROWTHBOOK_CLIENT_KEY: string;
    GROWTHBOOK_DECRYPTION_KEY: string;
    NODE_ENV: string;
  };
}
const { publicRuntimeConfig } = getConfig() as Config;

export interface GrowthbookProps {
  growthbookData?: {
    payload: Record<string, unknown>;
  };
}

export const withGrowthbookProvider = <P extends object>(
  WrappedComponent: NextPage<P>
): ((
  props: P &
    GrowthbookProps &
    CookieProps & { device: string; query: Record<string, string> }
) => JSX.Element) => {
  const WrappedComponentWithGrowthbookProvider = (
    props: P &
      GrowthbookProps &
      CookieProps & {
        device: string;
        pathname?: string;
        asPath?: string;
        query: Record<string, string>;
      }
  ) => {
    const { asPath, device, growthbookData, ...rest } = props;
    const { isoAlpha2 } = useCountryData();
    const { cookies, pathname, query } = rest;
    const { payload } = growthbookData ?? {};

    const isProduction = publicRuntimeConfig.NODE_ENV === 'production';

    const { data } = useGetUserSubscriptionDetailsOptimizelyQuery();
    const isCancelled = data?.user?.isCancelled;
    const hasPaidAccess = data?.user?.hasPaidAccess;

    const userId = cookies?.userid;
    const growthbookOptOut = cookies?.optimizelyOptOut;

    const forcedGrowthbookVariation =
      typeof query?.growthbook_variation === 'string'
        ? parseInt(query.growthbook_variation, 10)
        : undefined;

    const growthbook = useMemo(() => {
      // Create attributes object for targeting
      const attributes = {
        asPath,
        countryIsoAlpha2: isoAlpha2,
        device,
        growthbook_variation: forcedGrowthbookVariation,
        id: userId, // Using the existing userId from cookies
        is_cancelled: isCancelled,
        is_logged_in: !!userId,
        is_opted_out: !!growthbookOptOut,
        is_paid_user: hasPaidAccess,
        path: pathname,
        query: JSON.stringify(query),
      };

      return new GrowthBook({
        apiHost: 'https://cdn.dropboxexperiment.com',
        attributes,
        clientKey: publicRuntimeConfig.GROWTHBOOK_CLIENT_KEY,
        decryptionKey: publicRuntimeConfig.GROWTHBOOK_DECRYPTION_KEY,
        enableDevMode: !isProduction,
        trackingCallback: (experiment, result) => {
          if (isServerSide()) {
            return;
          }

          if (!isProduction) {
            console.log('Growthbook Experiment Viewed:', {
              experimentId: experiment.key,
              variationId: result.key,
            });
          }
        },
      });
    }, [
      asPath,
      device,
      forcedGrowthbookVariation,
      hasPaidAccess,
      isCancelled,
      isProduction,
      pathname,
      query,
      userId,
      growthbookOptOut,
      isoAlpha2,
    ]);

    if (payload?.status === 200) {
      growthbook.initSync({
        payload,
        streaming: false,
      });
    }

    useEffect(() => {
      if (forcedGrowthbookVariation !== undefined) {
        void growthbook.setAttributes({
          growthbook_variation: forcedGrowthbookVariation,
        });
      }
    }, [forcedGrowthbookVariation, growthbook]);

    return (
      <GrowthBookProvider growthbook={growthbook}>
        <WrappedComponent {...props} />
      </GrowthBookProvider>
    );
  };

  (WrappedComponentWithGrowthbookProvider as NextPage).getInitialProps = async (
    context: NextPageContext
  ) => {
    const { asPath, pathname, query } = context;
    const cookies = getCookies(context);

    let payload = {};
    try {
      // Create and initialize a GrowthBook instance on the server
      const gb = new GrowthBook({
        apiHost: 'https://cdn.dropboxexperiment.com',
        clientKey: publicRuntimeConfig.GROWTHBOOK_CLIENT_KEY,
        decryptionKey: publicRuntimeConfig.GROWTHBOOK_DECRYPTION_KEY,
        enableDevMode: publicRuntimeConfig.NODE_ENV === 'development',
      });

      await gb.init({ timeout: 1000 });

      payload = gb.getDecryptedPayload();

      gb.destroy();
    } catch (e) {
      Sentry.captureException(e);
    }

    const growthbookData = {
      payload,
    };

    const userAgent = context.req
      ? context.req.headers['user-agent']
      : navigator.userAgent;
    const device = getUserDevice(userAgent);

    return {
      ...((await WrappedComponent.getInitialProps?.(context)) ?? {}),
      asPath,
      cookies,
      device,
      growthbookData,
      pathname,
      query,
    };
  };

  return WrappedComponentWithGrowthbookProvider;
};
